<ng-template #header>
  <h2>
    <span><i class="fal fa-crop"></i></span>{{ 'Crop Image' | translate }}
  </h2>
</ng-template>
<ng-template #content>
  <div class="crop-image">
    <div class="image-wrapper original--image">
      <div class="images crop--images">
        <ng-container *ngIf="cropperImage">
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [imageBase64]="base64Image"
            [aspectRatio]="browseOption.aspectRatio"
            [resizeToWidth]="browseOption.Width"
            [resizeToHeight]="browseOption.Height"
            [cropperMinWidth]="browseOption.Width"
            [cropperMinHeight]="browseOption.Height"
            [maintainAspectRatio]="true"
            [roundCropper]="false"
            format="png"
            outputType="base64"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady($event)"
            (loadImageFailed)="loadImageFailed()"
            [style.display]="showCropper ? null : 'none'"
            [hideResizeSquares]="false"
          ></image-cropper>
        </ng-container>

        <ng-container *ngIf="!cropperImage">
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [imageBase64]="base64Image"
            [maintainAspectRatio]="isFromAccountProfileImage"
            [resizeToHeight]="cropSettings.height"
            [roundCropper]="isFromAccountProfileImage"
            format="png"
            outputType="base64"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady($event)"
            (loadImageFailed)="loadImageFailed()"
            [style.display]="showCropper ? null : 'none'"
          ></image-cropper>
        </ng-container>
        <!-- <image-cropper [imageChangedEvent]="imageChangedEvent" [imageBase64]="base64Image" [roundCropper]="false" [resizeToHeight]="cropSettings.height"
          format="png"  (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
           (loadImageFailed)="loadImageFailed()"></image-cropper> -->
      </div>
      <p>{{ 'Original' | translate }}</p>
    </div>
    <div class="image-wrapper cropped--image">
      <div class="image">
        <img [class.circular-image]="isFromAccountProfileImage" [src]="croppedImage" />
        <p>{{ 'Preview' | translate }}</p>
        <p class="dimensions">
          <ng-container *ngIf="!cropperImage">({{ cropperEvent?.width }} x {{ cropperEvent?.height }}) px</ng-container>
          <ng-container *ngIf="cropperImage"
            >({{ browseOption?.WidthText }} x {{ browseOption?.HeightText }}) px</ng-container
          >
        </p>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #footer>
  <button translate class="btn" (click)="saveImage()">Save</button>
  <button translate *ngIf="this.config.data.skippable" class="btn btn--cancel" (click)="skip()">Skip</button>
  <button translate class="btn btn--cancel" (click)="cancel()">Cancel</button>
</ng-template>
