import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Injector,
} from '@angular/core';
import { FiltersService } from '@app/shared/services/filters.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GlobalFilterService } from '../../global-filters.service';
import { GlobalFilterHelperService } from '../../global-filters-helper.service';
import { GbfBase } from '../gbf-base';
import { GlobalFilterApiService } from '../../global-filters.api.service';
import { GlobalFilterConfigService } from '../../global-filters-config.service';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { HeaderTabService } from '@app/shared/services/header-tab.service';
import { AuthenticationService } from '@app/core';
import moment from 'moment';
import { LoaderService } from '@app/core/loader.service';

@Component({
  selector: 'app-gbf-tree',
  templateUrl: 'global-filter-tree.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalFilterTreeComponent extends GbfBase implements OnInit, OnDestroy {
  Object = Object;
  activeTree: any = {};
  search = '';
  render = true;
  searchChanged: Subject<string> = new Subject<string>();
  public suggestions: any[] = [];
  searchItems: any[] = [];
  breadcrums: any = [];
  searchLoader: boolean;
  ctx = this;
  useWorker = false;
  subscriptions: Subscription[] = [];
  @Input() active = false;
  @Output() offmenu: any = new EventEmitter();
  worker: Worker = null;
  loaderType = 'main';
  constructor(
    public injector: Injector,
    public headerTabService: HeaderTabService,
    public authService: AuthenticationService,
    public gbfHelperService: GlobalFilterHelperService,
    public gbfApiService: GlobalFilterApiService,
    public gbfService: GlobalFilterService,
    public gbfConfig: GlobalFilterConfigService,
    public cd: ChangeDetectorRef,
    public translateService: TranslateService,
    public loaderService: LoaderService
  ) {
    super(injector, gbfService, gbfHelperService, translateService);
    this.searchChanged.pipe(debounceTime(250)).subscribe((x) => {
      this.buildSearch(x);
    });
    this.subscriptions.push(
      gbfApiService.apiChange.subscribe((config: any) => {
        if (config.name === this.config.name) {
          this.init();
          this.retriveOldTree();
        }
      })
    );
    this.subscriptions.push(
      gbfService.gbfResetState.subscribe((key: any) => {
        if (this.config.name === key) {
          this.close({});
        }
      })
    );
    this.subscriptions.push(
      this.gbfService.gbfChangeState.subscribe((val: { config: any; value: any; for: string }) => {
        if (val.config.name === this.config.name && val.for === 'tree') {
          val.value = Array.isArray(val.value) ? val.value : [val.value];
          val.value.forEach((v: any) => {
            this.makeTreeSelection({ id: v.id || v.NodeId || v });
          });
        }
      }),
      gbfService.gbfDateChange.subscribe((event: any) => {
        if (!this.config.settings?.multi && this.config.name.indexOf('org') > -1) {
          let foundIndex = -1;
          Object.keys(this.activeTree).forEach((keyIndex: any) => {
            if (this.activeTree[keyIndex] && Array.isArray(this.activeTree[keyIndex])) {
              if (foundIndex === -1) {
                this.activeTree[keyIndex] = this.activeTree[keyIndex].map((item: any) => {
                  item.hide = this.shouldHide(item);
                  if (item.hide && (item.active || item.IsChecked || item.IsPartialChecked)) {
                    foundIndex = parseInt(keyIndex);
                  }
                  if (item.hide) {
                    item.IsChecked = false;
                    item.IsPartialChecked = false;
                    item.active = false;
                  }
                  return item;
                });
              } else {
                if (!this.config.settings?.multi) {
                  this.activeTree[keyIndex] = [];
                }
              }
            }
          });
          if (foundIndex !== -1) {
            const found: any = this.activeTree[foundIndex > 0 ? foundIndex - 1 : 0].find((x: any) => x.active);
            if (found) {
              this.activeTree[foundIndex] = this.activeTree[foundIndex].filter((x: any) => !x.hide);
              this.onItemClicked({
                items: this.activeTree[foundIndex - 1],
                item: found,
                type: 'clickthrough',
                el: 'anchor',
                v: false,
              });
              this.cd.detectChanges();
            }
          }
          if (event?.offmenu !== false) {
            this.done({});
          }
        }
      })
    );
  }

  initWorker() {
    this.useWorker = this.config.useWorker === true;
    if (this.useWorker && typeof Worker !== 'undefined' && !this.worker) {
      this.worker = new Worker(new URL('../../filter.worker', import.meta.url), { type: 'module' });
      this.worker.onmessage = ({ data }: any) => {
        if (data.wtype === 'search') {
          this.searchItems = data.result;
          this.searchLoader = false;
          this.cd.detectChanges();
        } else if (data.wtype === 'onItemClicked') {
          if (data.self) {
            Object.keys(data.self).forEach((statekey: any) => {
              this[statekey] = data.self[statekey];
            });
          }
          if (data.gbfService) {
            Object.keys(data.gbfService).forEach((statekey: any) => {
              this.gbfService[statekey] = data.gbfService[statekey];
            });
          }
          if (data.scoped) {
            if (data.scoped.iitem) {
              this.setUpLocalStorage(data.scoped.iitem);
            }
          }
          this.cd.detectChanges();
          this.loaderService.setLoaders(false);
          const activeLevel = this.getActiveLevel();
          this.gbfService.buildOrgSuggestions(activeLevel + 1);
        }
      };
    }
  }

  getActiveLevel() {
    let level = -1;
    if (this.config.name.indexOf('org') == -1 || !this.config.settings.multi) {
      return level;
    }
    for (let _level in this.activeTree) {
      if (
        Array.isArray(this.activeTree[_level]) &&
        this.activeTree[_level].length > 0 &&
        this.activeTree[_level].find((x: any) => x.IsChecked && !x.hide)
      ) {
        level = Number(_level);
      }
    }
    return level;
  }
  async retriveOldTree() {
    const storageVal = await this.setLocalStorageValue();
    const isMarked = localStorage.getItem(this.config.localStorageKey + '_marked');
    if (this.config.settings.stright) {
      if (this.gbfService[this.config.name]?.level && this.gbfService[this.config.name]?.parent) {
        this.activatedLevel = this.gbfService[this.config.name].level;
        this.activatedParent = this.gbfService[this.config.name].parent;
      }
      this.buildActivatedTree();
    } else {
      if (storageVal.tree && Object.keys(storageVal.tree).length > 0) {
        this.breadcrums = [];
        Object.keys(storageVal.tree).forEach((key: any) => {
          const item = storageVal.tree[key].find((x: any) => x && x.active === true);
          this.activeTree[key] = storageVal.tree[key];
          if (item) {
            this.breadcrums.push(item);
          }
        });
      }
    }
    //Commented this code, For Multi Org
    //if (!this.config.settings.multi) {
    const records = this.gbfHelperService[this.config.property];
    if (Array.isArray(records) && records.length > 0) {
      if (isMarked !== 'true' && this.gbfHelperService[this.config.property].length > 0) {
        this.markSelectedAndDsiabled();
      }
    }
    //}
    this.cd.detectChanges();
  }
  makeTreeSelection(value: any) {
    if (this.gbfHelperService[this.config.property]) {
      this.gbfHelperService[this.config.property] = this.gbfHelperService[this.config.property].map((x: any) => {
        return {
          ...x,
          IsChecked: false,
          active: false,
        };
      });
    }
    const records = this.gbfHelperService[this.config.property] || [];
    let found = records.find((x: any) => x.id === value.id || x.mProps.NodeId === value.id);
    const futureTree: any[] = [found];
    for (let i = found.level - 1; i >= 1; i--) {
      found = records.find((x: any) => x.id === found.parent);
      futureTree.push(found);
    }
    futureTree.reverse().forEach((fTree: any) => {
      this.marker([fTree], [fTree], false);
    });
  }
  marker(items: any, found: any, init: boolean) {
    found[0].canNotBeSelected = false;
    found[0].active = true;
    found[0].IsChecked = true;
    const parsal = { canNotBeSelected: false, items: items, item: found[0], type: 'clickthrough', el: 'anchor', init };
    this.onItemClickedUIThread(parsal);
  }
  markSelectedAndDsiabled() {
    const records = this.gbfHelperService[this.config.property];
    let selectedLevel = this.authService?.authData?.MainNodeLevel || 1;
    if (this.config.settings.maxLevel) {
      selectedLevel = this.config.settings.maxLevel - 1;
    }
    let hasMultiNodes = false;

    for (let level = 1; level <= selectedLevel; level++) {
      const found = records.filter((x: any) => x.level === level);
      if (found.length === 1) {
        const items = records.filter((x: any) => x.level === level + 1) || [];
        found[0].active = true;
        if (level < selectedLevel && items.length < 2 && !hasMultiNodes) {
          found[0].disabled = !this.config.settings?.allow_parent ? true : false;
        }
        this.marker(items, found, selectedLevel === level || items.length > 1);
        if (items.length > 1) {
          const initialSelectedFilter =
            this.authService?.authData?.FilterBarConfig?.FilterConfig?.initialStates?.default_selection;
          const isNationUser = this.authService?.authData?.DataAccessLevels[0];
          if (isNationUser.Level == 1 && initialSelectedFilter) {
            const found1 = records.filter((x: any) => x.id === initialSelectedFilter._id);
            found1[0].active = true;
            found1[0].disabled = !this.config.settings?.allow_parent ? true : false;
            const items1 =
              records.filter((x: any) => x.level === found1[0].level + 1 && x.parent == found1[0].id) || [];
            this.marker(items1, found1, false);
          }
          break;
        }
      } else {
        break;
      }
    }

    // if(parentId){
    //   const treeSelection = []
    //   let dataFromParent:any = records.find((x: any)=> x.parent == parentId);
    //   treeSelection.push(dataFromParent);
    //    do {
    //      dataFromParent = records.find((x: any) => x.id == dataFromParent.parent);
    //      treeSelection.push(dataFromParent);
    //    }
    //    while (dataFromParent.parent);
    //   for (let level = 1; level <= selectedLevel; level++) {
    //      const found = treeSelection.filter((x: any) => x.level === level);
    //      if (found.length === 1) {
    //        let isLevelFound = false;
    //        let increaseLevel = 1;
    //        do {
    //          const items = treeSelection.filter((x: any) => x.level === level + increaseLevel) || [];
    //          if(items.length == 1 ){
    //            found[0].active = true;
    //            if (level < selectedLevel && items.length < 2 && !hasMultiNodes) {
    //              found[0].disabled = !this.config.settings?.allow_parent ? true : false;
    //            }
    //            this.marker(items, found, selectedLevel === level || items.length > 1);
    //            isLevelFound = true;
    //          } else {
    //            if(selectedLevel === level){
    //              const items = records.filter((x: any) => x.level === level + increaseLevel) || [];
    //              found[0].active = true;
    //              if (level < selectedLevel && items.length < 2 && !hasMultiNodes) {
    //                found[0].disabled = !this.config.settings?.allow_parent ? true : false;
    //              }
    //              this.marker(items, found, selectedLevel === level || items.length > 1);
    //              isLevelFound = true;
    //            }
    //            increaseLevel = increaseLevel + 1;
    //          }
    //        }
    //        while (!isLevelFound);
    //      }
    //   }
    //  }

    this.cd.detectChanges();
    localStorage.setItem(this.config.localStorageKey + '_marked', 'true');
  }
  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  init() {
    this.activatedLevel = this.config.startLevel;
    this.gbfHelperService[this.config.property] = [];
    this.activeTree = { [this.config.startLevel]: [], [this.config.startLevel + 1]: [] };
    if (this.config.name.indexOf('org') > -1) {
      this.gbfHelperService.treeToLinearN(this.gbfApiService[this.config.property], this.config.property);
    } else if (this.config.treeToLinear && this.config.property && this.gbfApiService[this.config.property]) {
      this.gbfHelperService.treeToLinear(this.gbfApiService[this.config.property], this.config.property, true);
    }
    if (this.gbfHelperService[this.config.property] && this.gbfHelperService[this.config.property].length > 0) {
      if (!this.config.settings.stright) {
        const initialState = this.gbfConfig.filterConfig['initialStates'][this.config.name];
        if (initialState) {
          this.initialState = initialState;
          // this.breadcrums = [initialState];
        }
      }
      let rootElements: any[] = this.gbfHelperService[this.config.property].filter(
        (x: any) => x.level === this.config.startLevel
      );
      if (rootElements && rootElements.length > 0) {
        rootElements = rootElements.map((qa: any) => {
          if (Array.isArray((this.initialState as any).selected)) {
            let i_states = (this.initialState as any).selected.find((item: any) => item.value === qa.value);
            if (i_states) qa.IsChecked = true;
          }
          qa.parentName = '';
          return qa;
        });
        this.activeTree[this.config.startLevel] = rootElements;
        if (!this.config.settings.initialChecked || this.config.settings.initialChecked !== 'none') {
          if (!this.config.settings.stright) {
            rootElements[0].IsChecked = true;
            rootElements[0].active = true;
          }
          if (!this.breadcrums) {
            this.breadcrums = [];
          }

          if (this.breadcrums.length === 0 && !this.config.settings.stright) {
            this.breadcrums.push(rootElements[0]);
          }
          const rootChilds = this.gbfHelperService[this.config.property].filter(
            (y: any) => y.parent === rootElements[0].id
          );
          if (rootChilds && rootChilds.length > 0) {
            this.activeTree[this.config.startLevel + 1] = [
              ...this.activeTree[this.config.startLevel + 1],
              ...rootChilds,
            ].map((rc: any) => {
              rc.parentName = rc.parentName || rootElements[0].name;
              return rc;
            });
          }
        }
      }
    }
  }
  async ngOnInit() {
    this.initWorker();
    if (this.gbfApiService[this.config.property]) {
      if (this.gbfApiService.apiConfig[this.config.name] !== undefined) {
        if (this.gbfApiService[this.config.property].length <= 0) {
          this.gbfApiService.apiConfig[this.config.name](this.config);
        }
      }
    }
    this.init();
    await this.retriveOldTree();
  }
  shouldHide(element: any) {
    if (!this.config.settings?.multi && this.config.name.indexOf('org') > -1) {
      if (element && element.mProps.IsTerminated && element.mProps.TerminationDate) {
        const found = moment(element.mProps.TerminationDate).format(this.gbfService.dateFormat);
        const dates = this.gbfService.getDates('', '');
        if (
          moment(found).isBetween(dates.StartDate, dates.EndDate, undefined, '(]') ||
          moment(found).isAfter(dates.EndDate)
        ) {
          element.hide = false;
          return false;
        } else {
          element.hide = true;
          return true;
        }
      }
    }
  }

  getTitle() {
    if (this.config.settings.multi) {
      let title = '';
      if (
        this.config.title &&
        this.config.title.length > 0 &&
        this.gbfService[this.config.name] &&
        this.gbfService[this.config.name].length > 0
      ) {
        const item = this.gbfService[this.config.name][0];
        this.config.title.forEach((t: string) => {
          if (item[t]) {
            title += this.translateService.instant(item[t]);
          }
        });
        if (this.gbfService[this.config.name].length > 0) {
          const maxOrgLevel = Math.max.apply(
            Math,
            this.gbfService[this.config.name].map((o: any) => {
              return o.level;
            })
          );
          const levelSelection = this.gbfService[this.config.name].filter((x: any) => x.level === maxOrgLevel);
          if (levelSelection.length > 1) {
            if (this.config.name === 'surveyResponse' || this.config.defaultTitle) {
              title = this.translateService.instant('Multiple Selected');
            } else {
              title = `${levelSelection[0].label} ${this.translateService.instant('and')} ${
                levelSelection.length - 1
              } ${this.translateService.instant('More Selected')}`;
            }
          } else if (levelSelection.length === 1) {
            title = `${levelSelection[0].label}`;
          } else {
            title = this.translateService.instant('Selected');
          }
          // ` and ${this.gbfService[this.config.name].length - 1} More...`;
        }
      } else {
        title = this.translateService.instant(this.config.placeholder);
      }
      return title;
    } else {
      let title = '';
      if (this.config.title && this.config.title.length > 0 && this.gbfService[this.config.name]) {
        this.config.title.forEach((t: string) => {
          if (this.gbfService[this.config.name]) {
            if (this.gbfService[this.config.name][t] !== undefined) {
              title += this.gbfService[this.config.name][t] + ' ';
            } else if (this.gbfService[this.config.name].mProps !== undefined) {
              title += this.gbfService[this.config.name].mProps[t] + ' ';
            }
          }
        });
      } else {
        title = this.translateService.instant(this.config.placeholder);
      }
      if (title.trim()) {
        return this.translateService.instant(title.trimEnd());
      }
    }
  }
  onNestedBreadcrumClick(parsal: any) {
    if (parsal.canNotBeSelected) {
      return;
    }

    if (this.breadcrums.length > 0) {
      this.breadcrums = this.breadcrums.splice(0, parsal.level);
      this.gbfService[this.config.name + '_breadcrums'] = cloneDeep(this.breadcrums);
      Object.keys(this.activeTree).forEach((key: any) => {
        if (parsal.level + 1 < key) {
          this.activeTree[key] = [];
        }
      });
      Object.keys(this.activeTree).forEach((index: any) => {
        const indx = parseInt(index, 0);
        if (this.activeTree[indx].length > 0 && indx > parsal.level) {
          this.activeTree[indx].forEach((aT: any) => {
            aT.IsChecked = false;
            aT.active = false;
          });
        }
      });
      if (!this.config.settings.listDoneButton) {
        this.gbfService[this.config.name] = { ...parsal, ...parsal.mProps };
        this.selectedItem = this.gbfService[this.config.name];
        if (this.config.localStorageKey) {
          const customActiveTree: any = this.buildCompleteActiveTree();
          this.setItemToLocalStorage(this.config.localStorageKey, {
            customTree: cloneDeep(customActiveTree.customTree),
            selectedTreeItems: cloneDeep(customActiveTree.items),
            selected: cloneDeep(this.selectedItem),
            breadcrums: cloneDeep(this.breadcrums),
            tree: this.activeTree,
          });
        }
      }
      this.callApiOn('selection');
    }
  }

  selectSearchItem(items: any) {
    const bItems: any[] = [];
    if (items.length > 0) {
      this.activeTree = {};
      items.forEach((item: any) => {
        if (this.gbfHelperService[this.config.property] && this.gbfHelperService[this.config.property].length > 0) {
          bItems.push(item);
          const elements: any[] = this.gbfHelperService[this.config.property].filter(
            (x: any) => x.level === item.level && x.parent === item.parent
          );
          elements.forEach((element: any) => {
            element.IsChecked = false;
            element.active = false;
            element.parentName = item.name;
          });
          const index = elements.findIndex((x: any) => x.id === item.id);
          if (index !== -1) {
            elements[index].IsChecked = true;
            elements[index].active = true;
          }
          this.activeTree[item.level] = elements;
        }
      });
      if (items && items.length) {
        const lastItem = items[items.length - 1];
        if (lastItem) {
          this.onItemClicked({
            items: [{ ...lastItem, ...lastItem.mProps }],
            item: { ...lastItem, ...lastItem.mProps },
            type: 'tree',
            el: 'none',
          });
        }
      }
      this.search = '';
      this.cd.detectChanges();
    }
    this.breadcrums = bItems;
    this.done({});
  }

  onListBreadcrumClick(parsal: { index: number; item: any }) {
    if (parsal.index !== -1) {
      this.breadcrums = this.breadcrums.splice(0, parsal.index + 1);
      this.activatedParent = parsal.item.id;
      this.activatedLevel = parsal.item.level + 1;
    } else {
      this.breadcrums = [];
      this.activatedParent = 'null';
      this.activatedLevel = 1;
      if (this.gbfConfig.filterConfig.initialStates[this.config.name]) {
        parsal.item = this.gbfConfig.filterConfig.initialStates[this.config.name];
        parsal.item.temp = true;
      }
    }
    this.gbfService[this.config.name] = parsal.item;
    this.gbfService[this.config.name + '_breadcrums'] = cloneDeep(this.breadcrums);
    const customActiveTree: any = this.buildCompleteActiveTree();
    this.setItemToLocalStorage(this.config.localStorageKey, {
      customTree: cloneDeep(customActiveTree.customTree),
      selectedTreeItems: cloneDeep(customActiveTree.items),
      selected: cloneDeep(parsal.item),
      breadcrums: cloneDeep(this.breadcrums),
      tree: this.activeTree,
    });
    this.buildActivatedTree();
    this.callApiOn('selection');
  }
  handleAll(parsal: any) {
    parsal.items.forEach((item: any, index: number) => {
      if (index === 0) {
        this.activeTree[item.level + 1] = [];
      }
      const rootChilds = this.gbfHelperService[this.config.property].filter((y: any) => y.level === item.level + 1);
      if (rootChilds && rootChilds.length > 0) {
        let tItems = rootChilds;
        let shouldCheck = true;
        if (this.config.settings.partial !== true) {
          tItems = [...this.activeTree[item.level + 1], ...rootChilds];
          shouldCheck = false;
        }
        const cache = {};
        const levelVal: any = [];
        tItems.forEach((el: any) => {
          if (parsal.el !== 'anchor') {
            if (this.config.settings.multi && shouldCheck && item.IsChecked) {
              el.IsChecked = true;
              el.active = true;
            } else {
              el.IsPartialChecked = false;
              el.IsChecked = false;
              el.active = false;
            }
          }
          if (!cache[el.name]) {
            cache[el.name] = el;
            levelVal.push(el);
          }
        });
        this.activeTree[item.level + 1] = levelVal;
      }
    });
    this.breadcrums = this.breadcrums.splice(0, parsal.item.level - 1);
    this.breadcrums.push(parsal.item);
    this.gbfService[this.config.name + '_breadcrums'] = cloneDeep(this.breadcrums);
    const iitem = this.getAllSelectionVal(parsal.item);
    this.setUpLocalStorage(iitem);
  }
  onItemClickedWorker(parsal: { items: any[]; item: any; type: string; el: string; v?: boolean; init?: boolean }) {
    if (this.worker) {
      this.loaderService.setLoaders(false);
      if (parsal.items.length > 7) {
        this.loaderService.showMain = true;
      } else {
        this.loaderService.showTop = true;
      }

      this.worker.postMessage({
        type: 'onItemClicked',
        parsal: parsal,
        headerTabServiceActiveItem: this.headerTabService.activeItem,
        MainNodeLevel: this.authService.authData.MainNodeLevel,
        config: this.config,
        getLocalData: JSON.parse(localStorage.getItem(this.config.localStorageKey)),
        breadcrums: cloneDeep(this.breadcrums),
        activeTree: this.activeTree,
        activatedLevel: this.activatedLevel,
        activatedParent: this.activatedParent,
        dateFormat: this.gbfService.dateFormat,
        dates: this.gbfService.getDates('', ''),
        items: this.gbfHelperService[this.config.property],
      });
    }
  }
  onItemClickedUIThread(parsal: { items: any[]; item: any; type: string; el: string; v?: boolean; init?: boolean }) {
    if (parsal.item.canNotBeSelected) {
      return;
    }
    if (parsal.el === '__ALL__' && this.config.settings.allSelection === true) {
      this.handleAll(parsal);
      return;
    }
    // if (this.config.localStorageKey) {
    //   const getLocalData = JSON.parse(localStorage.getItem(this.config.localStorageKey));
    //   if (getLocalData && getLocalData.breadcrums) {
    //     this.breadcrums = getLocalData.breadcrums;
    //   }
    // }
    if (this.config.settings.stright && parsal.type !== 'selection' && parsal.item.hasChild) {
      this.activatedParent = parsal.item.id;
      this.activatedLevel = parsal.item.level + 1;
      if (this.config.property == 'categories') {
        const sameLevel = this.breadcrums.findIndex((breadcrum: any) => {
          return breadcrum.level == parsal.item.level;
        });
        sameLevel == -1 ? this.breadcrums.push(parsal.item) : (this.breadcrums[sameLevel] = parsal.item);
      } else {
        this.breadcrums.push(parsal.item);
      }
      this.buildActivatedTree();
      this.selectedItem = parsal.item;
      if (!this.config.settings.listDoneButton) {
        this.gbfService[this.config.name] = parsal.item;
        if (this.config.localStorageKey) {
          this.gbfService[this.config.name + '_breadcrums'] = cloneDeep(this.breadcrums);
          const customActiveTree: any = this.buildCompleteActiveTree();
          this.setItemToLocalStorage(this.config.localStorageKey, {
            customTree: cloneDeep(customActiveTree.customTree),
            selectedTreeItems: cloneDeep(customActiveTree.items),
            selected: cloneDeep(parsal.item),
            tree: this.activeTree,
            breadcrums: cloneDeep(this.breadcrums),
          });
        }
      }
    } else {
      Object.keys(this.activeTree).forEach((key: any) => {
        if (parsal.items.length > 0) {
          parsal.items.forEach((item: any) => {
            if (item.level < key) {
              this.activeTree[key] = [];
            }
          });
        } else {
          if (parsal.item.level < key) {
            this.activeTree[key] = [];
          }
        }
      });
      if (parsal.init == false) {
        this.activeTree[parsal.item.level + 1] = parsal.items;
      }
      if (parsal.init == undefined) {
        parsal.items.forEach((item: any, index: number) => {
          if (index === 0) {
            this.activeTree[item.level + 1] = [];
          }
          var rootChilds = this.gbfHelperService[this.config.property]
            .filter((y: any) => y.parent === item.id)
            .map((rc: any) => {
              rc.parentName = item.name;
              return rc;
            });
          if (
            (this.headerTabService.activeItem === 'Social Media' &&
              this.authService.authData.MainNodeLevel === parsal.item.level) ||
            parsal.item.level >= this.config.settings.maxLevel
          ) {
            rootChilds = null;
          }
          if (rootChilds && rootChilds.length > 0) {
            let tItems = rootChilds;
            let shouldCheck = true;
            if (this.config.settings.partial !== true) {
              tItems = [...this.activeTree[item.level + 1], ...rootChilds];
              shouldCheck = false;
            }
            tItems = this.mergeChilds(item.level, tItems);
            this.activatedLevel = item.level + 1;
            this.activeTree[item.level + 1] = tItems.map((el: any) => {
              el.hide = this.shouldHide(el);
              if (parsal.el !== 'anchor') {
                if (this.config.settings.multi && shouldCheck && item.IsChecked) {
                  el.IsChecked = true;
                  el.active = true;
                } else {
                  el.IsPartialChecked = false;
                  el.IsChecked = false;
                  el.active = false;
                }
              }
              return el;
            });
          }
          if (this.config.settings.partial === true) {
            this.setPartial(parsal, item);
          }
        });
      }
      if (parsal.item.isRadio && !this.config.settings.allSelection) {
        const sameLevelNodes = this.gbfHelperService[this.config.property]
          .filter((y: any) => y.level === parsal.item.level)
          .map((rc: any) => {
            rc.hide = this.shouldHide(rc);
            if (parsal.item.id === rc.id) {
              rc.IsChecked = true;
              rc.active = true;
            } else {
              rc.IsChecked = false;
              rc.active = false;
            }
            return rc;
          });
        var nextLevel = this.activeTree[parsal.item.level];
        if (nextLevel != null) {
          const sameLevelNodesWithSameParent = sameLevelNodes.filter(
            (x: any) => x.parent === this.activeTree[parsal.item.level][0]?.parent
          );
          this.activeTree[parsal.item.level] = sameLevelNodesWithSameParent;
        }
      }
      this.breadcrums = this.breadcrums.splice(0, parsal.item.level - 1);
      this.breadcrums.push(parsal.item);
      this.gbfService[this.config.name + '_breadcrums'] = cloneDeep(this.breadcrums);
      const iitem = this.getAllSelectionVal(parsal.item);
      iitem.type = parsal.type;
      this.setUpLocalStorage(iitem);
    }
    // if (parsal.item.IsChecked === false) {
    //   this.gbfHelperService[this.config.property].filter((y: any) => y.id === parsal.item.parent).forEach((q: any) => {
    //       q.IsPartialChecked = false;
    //       q.IsChecked = false;
    //       q.active = false;
    //   });
    // }
    if (parsal.type === 'selection' && parsal.v === false) {
      this.activeTree[parsal.item.level + 1] = [];
    }
    this.callApiOn('selection');
    const activeLevel = this.getActiveLevel();
    this.gbfService.buildOrgSuggestions(activeLevel + 1);
    this.cd.detectChanges();
  }

  mergeChilds(parentLevel: number, childs: any[]) {
    if (!this.config.mergeChilds) return childs;
    for (let i = 1; i <= parentLevel; i++) {
      let levelValues = this.activeTree[i].filter((x: any) => x.IsChecked).map((x: any) => x.label);
      childs = childs.filter((x) => levelValues.includes(x.mProps['Level' + i]));
    }
    return [...new Map(childs.map((item) => [item['label'], item])).values()];
  }

  onItemClicked(parsal: any) {
    if (this.useWorker) {
      this.onItemClickedWorker(parsal);
    } else {
      this.onItemClickedUIThread(parsal);
    }
  }

  onSuggestionClicked($event: any) {
    this.gbfService.setSuggestions('', $event.items);
  }
  getAllSelectionVal(item: any) {
    if (this.config.name === 'model') {
      const m = {
        Model: '',
        Brand: '',
        Year: '',
      };
      if (this.config.mapping === 'ybm') {
        this.breadcrums.forEach((q: any, index: number) => {
          if (index === 0) {
            m.Year = q.Year;
          } else if (index === 1) {
            m.Brand = q.Brand;
          } else if (index === 2) {
            m.Model = q.Model;
          }
        });
      } else {
        this.breadcrums.forEach((q: any, index: number) => {
          if (index === 0) {
            m.Brand = q.Brand;
          } else if (index === 1) {
            m.Model = q.Model;
          } else if (index === 2) {
            m.Year = q.Year;
          }
        });
      }
      item = { ...item, ...m };
      return item;
    }
    return item;
  }
  refinebreadcrums(breadcrum: any) {}
  setUpLocalStorage(parsalItem: any) {
    if (!this.config.settings.listDoneButton || parsalItem.type === 'clickthrough') {
      if (this.config.settings.multi) {
        const items: any = [];
        Object.keys(this.activeTree).forEach((key: any) => {
          if (this.activeTree[key] && this.activeTree[key].length > 0) {
            this.activeTree[key].forEach((item: any) => {
              if (item.hide) {
                item.IsChecked = false;
                item.IsPartialChecked = false;
                item.active = false;
              }
              if (item && item.IsChecked) {
                items.push({ ...item, ...item.mProps });
              }
            });
          }
        });
        this.gbfService[this.config.name + '_breadcrums'] = cloneDeep(this.breadcrums);
        if (this.config.localStorageKey) {
          const customActiveTree: any = this.buildCompleteActiveTree();
          this.setItemToLocalStorage(this.config.localStorageKey, {
            customTree: cloneDeep(customActiveTree.customTree),
            selectedTreeItems: cloneDeep(customActiveTree.items),
            selected: cloneDeep(items),
            breadcrums: cloneDeep(this.breadcrums),
            tree: this.activeTree,
          });
        }
        this.selectedItem = items.filter((x: any) => x.IsChecked || x.IsPartialChecked || x.active);
        this.gbfService[this.config.name] = this.selectedItem;
      } else {
        this.gbfService[this.config.name] = parsalItem;
        if (this.config.localStorageKey) {
          const customActiveTree: any = this.buildCompleteActiveTree();
          this.setItemToLocalStorage(this.config.localStorageKey, {
            customTree: cloneDeep(customActiveTree.customTree),
            selectedTreeItems: cloneDeep(customActiveTree.items),
            selected: cloneDeep(parsalItem),
            breadcrums: cloneDeep(this.breadcrums),
            tree: this.activeTree,
          });
        }
        this.selectedItem = parsalItem;
      }
    } else {
      if (this.config.settings.multi) {
        const pitems: any = [];
        const aTree = cloneDeep(this.activeTree);
        Object.keys(aTree).forEach((key: any) => {
          if (aTree[key] && aTree[key].length > 0) {
            aTree[key].forEach((item: any) => {
              if (item && item.IsChecked) {
                pitems.push({ ...item, ...item.mProps });
              }
            });
          }
        });
        this.selectedItem = pitems;
      } else {
        this.selectedItem = parsalItem;
      }
    }
  }
  setPartial(parsal: any, item: any) {
    const isAllChecked: any[] = [];
    const parentItem = this.gbfHelperService[this.config.property].find((y: any) => y.id === item.parent);
    const parentChilds = this.gbfHelperService[this.config.property].filter((y: any) => y.parent === item.parent);
    // const itemChilds = this.gbfHelperService[this.config.property].filter((y: any) => y.parent === item.id);
    if (parentItem && parentChilds.length > 0) {
      this.activeTree[item.level] = this.activeTree[item.level].map((el: any) => {
        el.hide = this.shouldHide(el);
        if (parsal.el !== 'anchor') {
          if (this.config.settings.multi && el.IsChecked) {
            el.IsChecked = true;
            el.active = true;
          } else {
            el.IsPartialChecked = false;
            el.IsChecked = false;
            el.active = false;
          }
        }
        if (el.IsChecked) {
          isAllChecked.push(el);
        }
        return el;
      });
      if (parentChilds.length === isAllChecked.length) {
        // set item checked
        parentItem.IsChecked = true;
        parentItem.IsPartialChecked = false;
        this.activeTree[item.level - 1] = this.activeTree[item.level - 1].map((x: any) => {
          x.hide = this.shouldHide(x);
          if (x.id === parentItem.id) {
            x.IsChecked = true;
            x.IsPartialChecked = false;
          }
          return x;
        });
      } else if (isAllChecked.length > 0) {
        // set item partial checked
        parentItem.IsChecked = true;
        parentItem.IsPartialChecked = true;
        this.activeTree[item.level - 1] = this.activeTree[item.level - 1].map((x: any) => {
          x.hide = this.shouldHide(x);
          if (x.id === parentItem.id) {
            x.IsChecked = true;
            x.IsPartialChecked = true;
          }
          return x;
        });
      } else {
        // set item uncheck
        parentItem.IsChecked = false;
        parentItem.IsPartialChecked = false;
        this.activeTree[item.level - 1] = this.activeTree[item.level - 1].map((x: any) => {
          x.hide = this.shouldHide(x);
          if (x.id === parentItem.id) {
            x.IsChecked = false;
            x.IsPartialChecked = false;
          }
          return x;
        });
      }
    } else {
      this.activeTree[item.level] = this.activeTree[item.level].map((el: any) => {
        el.hide = this.shouldHide(el);
        if (parsal.el !== 'anchor' && el.id === item.id) {
          if (this.config.settings.multi && el.IsChecked && el.IsPartialChecked) {
            el.IsChecked = true;
            el.active = true;
            el.IsPartialChecked = true;
          } else if (this.config.settings.multi && el.IsChecked) {
            el.IsChecked = true;
            el.active = true;
            el.IsPartialChecked = false;
          } else {
            el.IsPartialChecked = false;
            el.IsChecked = false;
            el.active = false;
          }
        }
        return el;
      });
    }
    if (parentItem) {
      this.setPartial(parsal, parentItem);
    }
  }
  onSearch(query: string) {
    this.searchChanged.next(query);
  }
  makeSearchTree(item: any, index: any, searchItems: any) {
    let found = this.gbfHelperService[this.config.property].find((x: any) => x.id === item.parent);
    if (found) {
      searchItems[index].push(found);
      if (found.parent !== -1) {
        this.makeSearchTree(found, index, searchItems);
      }
    }
  }
  buildSearch(query: string) {
    this.searchItems = [];
    if (query && query.length < 2) {
      return;
    }
    this.searchLoader = true;
    if (this.worker) {
      this.worker.postMessage({
        items: this.gbfHelperService[this.config.property],
        query,
      });
    } else {
      const searchItems: any = [];
      this.gbfHelperService[this.config.property].forEach((item: any) => {
        if (
          query &&
          item &&
          item.label &&
          item.label.toLowerCase().search(this.gbfHelperService.escapeRegExp(query.toLowerCase())) !== -1
        ) {
          const insertedIndex = searchItems.push([item]) - 1;
          if (item.parent !== -1) {
            this.makeSearchTree(item, insertedIndex, searchItems);
            this.cd.detectChanges();
          }
        }
      });
      const _searchItems: any[] = [];
      searchItems.forEach((items: any[]) => {
        items = items.map((x) => {
          x.hide = this.shouldHide(x);
          return x;
        });
        let index = items.findIndex((x) => x.hide);
        if (index === -1) {
          items.reverse();
          this.cd.detectChanges();
          _searchItems.push(items);
        }
      });
      this.searchItems = _searchItems.slice(0, 150);
      this.searchLoader = false;
      setTimeout(() => {
        this.cd.detectChanges();
      }, 0);
    }
  }

  buildActivatedTree() {
    const items = this.gbfHelperService[this.config.property].filter((item: any) => {
      return item.level === this.activatedLevel && item.parent === this.activatedParent;
    });
    this.activatedItems = items;
  }
  close(e: any) {
    if (this.config.localStorageKey) {
      try {
        const getLocalData = JSON.parse(localStorage.getItem(this.config.localStorageKey));
        if (getLocalData && getLocalData.tree) {
          this.breadcrums = getLocalData.breadcrums;
          this.selectedItem = getLocalData.selected;
          this.activeTree = getLocalData.tree;

          if (Array.isArray(this.selectedItem)) {
            this.selectedItem = this.selectedItem.filter(
              (x: any) => (x.IsChecked || x.IsPartialChecked || x.active) && !x.hide
            );
            this.gbfService[this.config.name] = this.selectedItem;
          } else {
            this.gbfService[this.config.name] = this.selectedItem;
          }

          this.gbfService.gbfChange.next({ filter: this.config, val: this.selectedItem });
          this.gbfService[this.config.name + '_breadcrums'] = cloneDeep(this.breadcrums);
        }
      } catch (error: any) {}
    }
    if (e.stopPropagation && e.preventDefault) {
      e.stopPropagation();
      e.preventDefault();
      this.offmenu.emit(e);
    }
  }
  setReRender(callback?: any) {
    this.render = false;
    setTimeout(() => {
      this.render = true;
      if (callback) {
        callback();
      }
    }, 0);
  }
  getCheckedItems() {
    return this.gbfHelperService[this.config.property].filter((y: any) => y.IsChecked === true && !y.hide);
  }
  buildCompleteActiveTree() {
    const items = this.getCheckedItems();
    const maxLevel = Math.max.apply(
      Math,
      items.map((o: any) => o.level)
    );
    const customTree: any = {};
    for (let i = 1; i <= maxLevel; i++) {
      if (!customTree[i]) {
        customTree[i] = [];
        const levelItems = items.filter((x: any) => x.level === i);
        customTree[i] = levelItems;
      }
    }
    return { items, customTree };
  }
  done(e: any) {
    if (!this.selectedItem) {
      return;
    }

    if (Array.isArray(this.selectedItem)) {
      this.selectedItem = this.selectedItem.filter(
        (x: any) => (x.IsChecked || x.IsPartialChecked || x.active) && !x.hide
      );
      this.gbfService[this.config.name] = this.selectedItem;
    } else {
      this.gbfService[this.config.name] = this.selectedItem;
    }
    e.done = true;
    e.config = this.config;
    this.offmenu.emit(e);
    this.gbfService.gbfChange.next({ filter: this.config, val: this.selectedItem });
    this.gbfService[this.config.name + '_breadcrums'] = cloneDeep(this.breadcrums);
    if (this.config.localStorageKey) {
      // this.setItemToLocalStorage(this.config.localStorageKey, { selected: cloneDeep(this.selectedItem), tree: this.activeTree });
      const customActiveTree: any = this.buildCompleteActiveTree();
      this.setItemToLocalStorage(this.config.localStorageKey, {
        customTree: cloneDeep(customActiveTree.customTree),
        selectedTreeItems: cloneDeep(customActiveTree.items),
        selected: cloneDeep(this.selectedItem),
        breadcrums: cloneDeep(this.breadcrums),
        tree: this.activeTree,
      });
    }
    this.callApiOn('done');
  }
  checkExisits(t: any, i: any) {}
}
