import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ExportService {
  private rg$ = new Subject<any>();
  private subject$ = new Subject<any>();
  private showPrint$ = new Subject<any>();
  private exportAll$ = new Subject<any>();
  private changeVersion$ = new Subject<any>();
  public exportFileName: string = '';
  sendMessage(message: string) {
    this.subject$.next({ text: message });
  }

  clearMessages() {
    this.subject$.next(null);
  }

  getMessage(): Observable<any> {
    return this.subject$.asObservable();
  }

  sendMessageFromGlobalComponent(message: string) {
    this.showPrint$.next({ text: message });
  }

  clearMessagesFromGlobalComponent() {
    this.showPrint$.next(null);
  }
  gettRG() {
    return this.rg$.asObservable();
  }
  setRG() {
    this.rg$.next({});
  }

  getMessageFromGlobalComponent(): Observable<any> {
    return this.showPrint$.asObservable();
  }

  sendMessageForExportAll(message: string) {
    this.exportAll$.next({ text: message });
  }

  clearMessagesForExportAll() {
    this.exportAll$.next(null);
  }

  getMessageForExportAll(): Observable<any> {
    return this.exportAll$.asObservable();
  }
  setSurveyVerion(message: string) {
    this.changeVersion$.next({ text: message });
  }
  getSurveyVersion(): Observable<any> {
    return this.changeVersion$.asObservable();
  }
}
