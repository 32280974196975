<app-widget-dynamic
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
>
  <ng-container widgetHeader>
    <div class="widget-node">
      <a class="handler more" (click)="refresh($event)">
        <i class="far fa-redo"></i>
      </a>
    </div>
    <div class="widget-node"  *ngIf="clickThrough && hasCommentListPermission">
      <a class="handler more" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
    </div>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>
  <ng-container widgetArea>
    <div [attr.data-can-render]="canRender">
      <div *ngIf="data && data.CustomerComments" class="widget__content">
        <div class="web_only mobile_only">
          <cvp-scrollPanel #scrollBar [style]="{width: '100%', height: !isMobile ? '400px' : '100%' }">
            <ng-container *ngTemplateOutlet="customerTemplate"></ng-container>
          </cvp-scrollPanel>
        </div>
      </div>
      <div class="print_only">
        <ng-container *ngTemplateOutlet="customerTemplate"></ng-container>
      </div>
    </div>
    <div class="nodata-wrapper" *ngIf="!(data && data?.CustomerComments?.length)">
      <div class="nodata">
        <i class="nodata__icon fal fa-info-circle"></i>
        <p class="nodata__msg">{{'No Result Found' |translate}}</p>
      </div>
    </div>
    <ng-template #customerTemplate>
      <div class="customer-comment-container" *ngIf="data && data?.CustomerComments?.length">
        <div *ngFor="let customerComments of data.CustomerComments" class="customer-comment-row">
          <div class="customer-title-col">
            <div class="customer-title-label">
              <div class="label-prefix">{{customerComments.Label }}</div>
            </div>
            <div class="customer-title" *ngIf="customerComments.Title">{{customerComments.Title }}</div>
            <div class="customer-title-kpi">
              <!-- *ngIf="(customerComments.VariablesAlerttag || customerComments.OptionsAlerttag )" -->
              <div class="customer-title-kpi">
                <span *ngIf="customerComments.VariablesAlerttag">{{customerComments.VariablesAlerttag}}</span>
                <span *ngIf="customerComments.OptionsAlerttag">{{customerComments.OptionsAlerttag}}</span>
              </div>
            </div>
            <div class="customer-comments">
              <span *ngIf="customerComments.QuestionComments">{{customerComments.QuestionComments }}</span>
              <span *ngIf="customerComments.OptionComments">{{customerComments.OptionComments }}</span>
              <span *ngIf="customerComments.VariableComments">{{customerComments.VariableComments }}</span>
            </div>
            <div class="customer-bottom-row">
              <div class="label-question">
                <div class="radius">Q</div>
                <div class="radius2">{{customerComments.QuestionCode}}</div>
              </div>
              <div class="label-date" *ngIf="customerComments.CompletedCreatedOn">
                <span>{{customerComments.CompletedCreatedOn | customdateformat: "date" }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</app-widget-dynamic>
