import { Component, OnInit, OnDestroy, ViewChild, ElementRef, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import HighCharts from '@clearviewshared/highchart/highchart';
import { WidgetService } from '../widget.service';
import { CacheService } from '@app/shared/services/cache.service';
import { ISentimentCount, SentimentCountStatus } from '../widget-interface';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import {
  drawPieChartDot,
  generateSentimentCount,
  generateSentimentCountV2,
} from '@clearviewshared/highchart/helperfunctions';
import { Identifiers } from '@app/shared/services/app.config.type';
import { sentimentCountData } from '../widget-data';
import { FiltersService } from '@app/shared/services/filters.service';
import WidgetBase from '../widegt.base';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { AuthenticationService, I18nService } from '@app/core';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { GlobalFilterApiService } from '@app/shared/module/global-filters/global-filters.api.service';
import { zIndex } from 'html2canvas/dist/types/css/property-descriptors/z-index';
import Highcharts from 'highcharts';
import {
  DynamicFilters,
  FilterTypes,
  GlobalFilterRouter,
} from '@app/shared/module/global-filters/global-filter-router';
import moment from 'moment';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { Permission } from '@clearviewshared/constant/permission';
@Component({
  selector: 'dealer-variable-bonus-widget',
  templateUrl: './dealer-variable-bonus-widget.html',
  styleUrls: ['./dealer-variable-bonus-widget.component.scss'],
})
export class DVBWidgetComponent extends WidgetBase implements OnInit, OnDestroy {
  permissions: NgxPermissionsObject;
  routerPrefix = '';
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  @ViewChild('containerDVBCount', { read: ElementRef }) containerDVBCount: ElementRef;
  @ViewChild('containerDVBCount2', { read: ElementRef }) containerDVBCount2: ElementRef;
  @Input() dateFormat = 'MMM';
  @Input() module = 'reporting';
  // @Input() isVOC = false;
  noResult = false;
  canRender = false;
  colors = ['green', 'orange', 'red', 'gray'];
  data: any;
  dataModel: ISentimentCount[] = [];
  chartOptions: any = {};
  alertResoulation = 0;
  chart1: any;
  chart2: any;
  name: string;
  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  allSentiment: boolean;
  sentiment: any;
  isAspectCategorySelected = true;
  ac: any;
  api: Subscription;
  date: any;
  isToggleDisabled = false;
  private subscriptions: Subscription[] = [];

  res_ques: any = 0;
  res_ques_rea: any = 0;
  month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  res_ques_tar: any = 0;
  res_ques_rea_tar: any = 0;
  hasPostive_res: boolean = false;
  hasPositive_reach: boolean = false;

  required_percentage: any = 0;
  reach_percentage: any = 0;
  survey_count: any = 0;
  value: string = '';
  res_ques_rea_1: any;
  res_ques_1: any;
  user: any = {};
  denomResponse: any;
  hasClickThroughPermission: boolean = false;
  constructor(
    private router: Router,
    public widgetService: WidgetService,
    public translateService: TranslateService,
    private cacheService: CacheService,
    public toastService: ToastService,
    private appConfig: AppConfigurationService,
    private filterService: FiltersService,
    private translationHelper: TranslationHelperService,
    public i18nService: I18nService,
    public gbfService: GlobalFilterService,
    public gbfapiservice: GlobalFilterApiService,
    public authService: AuthenticationService,
    private gbfRouter: GlobalFilterRouter,
    public ngxPermission: NgxPermissionsService
  ) {
    super(gbfService, translateService);
    const widgetsRefreshThreshold = appConfig.getByIdentifier(Identifiers.WidgetsRefreshThreshold);
    if (widgetsRefreshThreshold) {
      let widgetThresholdVal;
      if (widgetsRefreshThreshold.ClientConfig === null) {
        widgetThresholdVal = widgetsRefreshThreshold['Fields'].equal.value;
      } else {
        if (widgetsRefreshThreshold.ClientConfig) {
          widgetThresholdVal = widgetsRefreshThreshold.ClientConfig['Fields'].equal.value;
        }
      }
      this.secondsCounter = interval(widgetThresholdVal);
    }
    this.user = this.gbfService.authService.getUser();
  }

  ngOnInit() {
    super.ngOnInit();
    this.setAspectCatPreset();
    const CurrenTab = JSON.parse(localStorage.getItem('gbf_tab'));
    this.getTabValueAssign(CurrenTab);
    this.loadWidget();
    this.subscriptions.push(
      this.cacheService.for(this.widgetService.dealerVariableBonus).subscribe((res: any) => {
        if (res) {
          this.data = res;

          this.initData();
          this.initData2();
          this.canRender = true;
        }
      })
    );

    this.subscriptions.push(
      this.widgetService.update.subscribe(() => {
        this.setAspectCatPreset();
        this.loadWidget();
      })
    );

    // this.secondsCounterSub = this.secondsCounter.subscribe((n: any) => {
    //   // this.loadWidget();
    // });
    this.gbfService.gbfOnApplyFilter.subscribe(() => {
      const surresp: any = JSON.parse(localStorage.getItem('gbf_survey_response'));
      if (
        this.gbfService.surveyQuestion.length !== this.gbfapiservice['surveyQuestions'].length ||
        (surresp &&
          surresp?.tree[1]?.filter((x: any) => x.IsChecked && !x.IsPartialChecked).length !== surresp.tree[1].length)
      ) {
        this.isToggleDisabled = true;
      } else {
        this.isToggleDisabled = false;
      }
    });
    this.gbfService.gbfTabChange.subscribe(() => {
      this.isToggleDisabled = false;
    });
    this.permissions = this.ngxPermission.getPermissions();
    this.hasClickThroughPermission = this.permissions[Permission.REPORT_DEALER_VARIABLE_BONUS] ? true : false;
  }

  setAspectCatPreset() {
    const aspectCategory = this.filterService.getAspectCategory();
    if (aspectCategory.label === 'All Categories') {
      this.isAspectCategorySelected = false;
      this.allSentiment = false;
    } else {
      this.isAspectCategorySelected = true;
      this.allSentiment = false;
    }
  }

  loadWidget() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getDealerVariableBonus().subscribe({
      next: (res: any) => {
        if (res) {
          this.calcAndMap(res);
          this.initData();
          this.initData2();

          this.canRender = true;
          this.widgetService.isAllLoaded = true;
        }
      },
      complete: () => {
        this.widgetService.isAllLoaded = true;
        this.canRender = true;
      },
      error: () => {
        this.noResult = true;
        this.widgetService.isAllLoaded = true;
        this.canRender = true;
      },
    });
  }

  initData() {
    const language = this.user.DefaultLanguage.split('-');
    var target = this.res_ques_tar;
    var value = Number(this.res_ques / 10);
    let color = this.res_ques_tar / 10 < Number(this.res_ques / 10) ? '#32CD32' : '#D22B2B';
    const qtd_start = moment().quarter(moment().quarter()).startOf('quarter').format(this.dateFormat);
    const qtd_end = moment().quarter(moment().quarter()).endOf('quarter').format(this.dateFormat);
    this.date =
      this.translateService.instant(qtd_start) +
      ' - ' +
      this.translateService.instant(qtd_end) +
      ' ' +
      new Date().getFullYear() +
      ' ' +
      this.translateService.instant('QTD');
    this.chartOptions = {};
    this.dataModel = [];
    if (this.res_ques_tar < Number(this.res_ques)) {
      this.hasPostive_res = true;
    }
    this.chartOptions = {
      chart: {
        type: 'solidgauge',
        height: '130px',
        margin: [10, 0, 0, 0],
        backgroundColor: 'transparent',
      },

      // title: null,
      credits: { enabled: false },
      title: {
        text: language[0] == 'en' ? Number(this.res_ques).toString() : this.i18nService.transformNumber(this.res_ques),
        y: 70,
        x: 7,
        style: { 'font-weight': 'bold', 'font-size': '20px', color: color },
      },
      subtitle: {
        text: this.translateService.instant(this.value),
        y: 90,
        x: 5,
        style: { 'font-weight': 'bold', 'font-size': '13px', color: color },
      },
      pane: {
        center: ['50%', '90%'],
        size: '180%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: '#EEE',
          innerRadius: '80%',
          outerRadius: '100%',
          shape: 'arc',
        },
      },
      tooltip: {
        enabled: false,
      },
      yAxis: {
        max: 100,
        stops: [[-9.9, color]],
        tickLength: 30,
        tickWidth: 3,
        tickPosition: 'inside',
        gridLineColor: 'transparent',
        lineColor: 'transparent',
        tickColor: 'white',
        tickInterval: 0,
        tickPositions: [this.res_ques_tar / 10],
        zIndex: 100,
        title: {
          y: -70,
        },
        labels: {
          enabled: false,
        },
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false,
            y: 5,
            borderWidth: 0,
            useHTML: true,
          },
        },
      },

      series: [
        {
          data: [Number(this.res_ques / 10)],
          innerRadius: '80%',
          outerRadius: '100%',
        },
      ],
    };

    this.chart1 = HighCharts['Reflecx'].createOrUpdate(
      this.chart1,
      this.containerDVBCount.nativeElement,
      this.chartOptions
    );
  }

  initData2() {
    const language = this.user.DefaultLanguage.split('-');
    let color = this.res_ques_rea_tar < Number(this.res_ques_rea) ? '#32CD32' : '#D22B2B';
    this.chartOptions = {};
    this.dataModel = [];
    if (this.res_ques_rea_tar < Number(this.res_ques_rea)) {
      this.hasPositive_reach = true;
    }
    this.chartOptions = {
      chart: {
        type: 'solidgauge',
        height: '130px',
        margin: [10, 0, 0, 0],
        backgroundColor: 'transparent',
      },
      credits: { enabled: false },
      title: {
        text:
          this.denomResponse ??
          (language[0] == 'en'
            ? Number(this.res_ques_rea).toString()
            : this.i18nService.transformNumber(this.res_ques_rea) + '%'),
        y: 70,
        x: 7,
        style: { 'font-weight': 'bold', 'font-size': '20px', color: color },
      },
      subtitle: {
        text: this.translateService.instant('Reach Rate %'),
        y: 90,
        x: 5,
        style: { 'font-weight': 'bold', 'font-size': '13px', color: color },
      },
      pane: {
        center: ['50%', '90%'],
        size: '180%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: '#EEE',
          innerRadius: '80%',
          outerRadius: '100%',
          shape: 'arc',
        },
      },
      tooltip: {
        enabled: false,
      },
      yAxis: {
        max: 100,
        stops: [[-9.9, color]],
        tickLength: 37,
        tickWidth: 3,
        min: 0,
        lineColor: 'transparent',
        minorTickLength: 0,
        tickPosition: 'inside',
        tickInterval: 0,
        tickPositions: [this.res_ques_rea_tar],
        tickColor: 'white',
        opposite: false,
        zIndex: 100,
        title: {
          y: -70,
        },
        labels: {
          enabled: false,
        },
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false,
            y: 5,
            borderWidth: 0,
            useHTML: true,
          },
        },
      },
      series: [
        {
          data: [Number(this.res_ques_rea)],
          innerRadius: '80%',
          outerRadius: '100%',
        },
      ],
    };
    this.chart2 = HighCharts['Reflecx'].createOrUpdate(
      this.chart2,
      this.containerDVBCount2.nativeElement,
      this.chartOptions
    );
    // drawPieChartDot(this.chart);
  }
  calcPrePercent(val: number, totalVal: number, decPlace: number) {
    let result = '0';
    const valArr = ((val / totalVal) * 100).toString().split('.');
    if (valArr.length > 1) {
      if (valArr[1].length > 0) {
        result = valArr[0].toString() + '.' + valArr[1].substring(0, decPlace);
      }
    } else {
      result = valArr[0].toString();
    }

    return result;
  }

  calcAndMap(data: any) {
    const language = this.user.DefaultLanguage.split('-');
    var currentTab = JSON.parse(localStorage.getItem('gbf_tab'));
    this.res_ques_tar = data.widget_config.target[currentTab.value.toLowerCase() + '_kpi'] ?? 0;
    this.res_ques_rea_tar = data.widget_config.target[currentTab.value.toLowerCase() + '_reach_rate'] ?? 0;
    if (data.hasOwnProperty('response_question') && data.response_question.length > 0) {
      this.res_ques = data.response_question[0].q_4_score.toFixed(1);
      this.res_ques_1 = language[0] == 'en' ? Number(this.res_ques) : this.i18nService.transformNumber(this.res_ques);
      this.res_ques_rea = data.reach_rate[0]?.reach_rate.toFixed(1) ?? 0;
      this.res_ques_rea_1 =
        language[0] == 'en' ? Number(this.res_ques_rea) : this.i18nService.transformNumber(this.res_ques_rea);
      this.survey_count = data.response_question[0].survey_count;
    }
    if (data.hasOwnProperty('reach_rate') && data.reach_rate.length > 0) {
      const { ReplaceKpiValue, ReplaceWith, reach_rate } = data.reach_rate[0];
      this.res_ques_rea = reach_rate.toFixed(1);

      if (ReplaceKpiValue) {
        this.denomResponse = ReplaceWith;
        this.res_ques_rea_1 = ReplaceWith;
      } else {
        this.res_ques_rea_1 =
          language[0] == 'en' ? Number(this.res_ques_rea) : this.i18nService.transformNumber(this.res_ques_rea);
      }
    }
  }

  sentimentChange(e: any) {
    this.loadWidget();
  }

  disableCtrl() {
    // this.allSentiment = false;
    // return false;
    // if(this.isAspectCategorySelected){
    // }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }
    if (this.api) {
      this.api.unsubscribe();
    }
  }
  route() {
    let cStart = moment(moment()).quarter(moment(moment()).quarter()).startOf('quarter');
    let cEnd = moment();
    const viewableParams: Array<DynamicFilters> = [];
    viewableParams.push({
      key: 'calendar',
      name: 'Calendar',
      value: [moment(cStart).format('MMM D, YYYY'), moment(cEnd).format('MMM D, YYYY')],

      //key: 'channels', name: 'Channel', value: 'SMS',
      type: FilterTypes.Global,
    });
    this.gbfRouter.navigate(
      ['/insights/reports/surveylist'],
      {},
      { key: '/insights/reports/surveylist', filters: viewableParams },
      () => {}
    );
  }

  redirectToIssueReport() {
    this.router.navigate(['/insights/reports/dealervariablebonus'], {});
  }

  getTabValueAssign(tabValue: any) {
    if (tabValue.label == 'Sales') {
      this.value = 'QP IPI';
    } else {
      this.value = 'QP ISI';
    }
  }
}
