import { Component, OnInit, OnDestroy, ViewChild, ElementRef, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Subscription, interval, Observable, fromEvent, debounceTime } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import HighCharts, { extraColors, getExtraColors } from '@clearviewshared/highchart/highchart';
import { WidgetService } from '../widget.service';
import { CacheService } from '@app/shared/services/cache.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import WidgetBase from '../widegt.base';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { I18nService } from '@app/core';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import * as Highcharts from 'highcharts';
@Component({
  selector: 'heat-map-widget',
  templateUrl: './heat-map.component.html',
  styleUrls: ['./heat-map.component.scss'],
})
export class HeatMapComponent extends WidgetBase implements OnInit, OnDestroy {
  _HighCharts = HighCharts;
  canRender = false;
  noResult = false;
  chartOptions: any = {};
  chart: any;
  calc_points: any[] = [];
  name: string;
  points: any = { data: [] };
  private subscriptions: Subscription[] = [];
  property: any[] = [];
  @ViewChild('chart', { read: ElementRef }) chartContainer: ElementRef;
  annualHeapMapClick: boolean = false;
  constructor(
    private router: Router,
    public widgetService: WidgetService,
    public gbfService: GlobalFilterService,
    public translateService: TranslateService,
    private cacheService: CacheService,
    public toastService: ToastService,
    private appConfig: AppConfigurationService,
    private translationHelper: TranslationHelperService,
    public i18nService: I18nService
  ) {
    super(gbfService, translateService);
    fromEvent(window, 'resize')
      .pipe(debounceTime(1500))
      .subscribe((event) => {
        this.loadChart(this.mapData(this.points || []));
      });
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadWidget();
    this.canRender = false;
    // this.subscriptions.push(
    //   this.cacheService.for(this.widgetService.getHeatMapUrl(0)).subscribe((res: any) => {
    //       if (res) {
    //         this.loadChart(this.mapData(res.data));
    //         this.canRender = true;
    //       }
    //   })
    // );
    if (this.from !== this.WIDGET_HOSTER) {
      this.subscriptions.push(
        this.widgetService.update.subscribe(() => {
          this.loadWidget();
        })
      );
      this.subscriptions.push(
        this.gbfService.gbfOnApplyFilter.subscribe(() => {
          this.loadWidget();
        })
      );
    }
  }
  loadWidget() {
    this.canRender = false;
    this.widgetService.getHeatMap(0, { apiConfig: this.apiConfig }).subscribe((res: any) => {
      this.canRender = true;
      this.points = res;
      this.loadChart(this.mapData(res));
    });
  }

  loadChart(points = new Array()) {
    if (this.chartContainer && this.chartContainer.nativeElement && this._HighCharts) {
      const chartOptions = {
        colors: ['#007bff'],
        colorAxis: {
          showInLegend: false,
          minColor: '#007bff',
          maxColor: '#007bff',
        },
        series: [
          {
            point: {
              events: {
                click: function (e: any) {},
              },
            },
            name: 'Regions',
            type: 'treemap',
            borderWidth: 0,
            layoutAlgorithm: 'squarified',
            levelIsConstant: false,
            allowDrillToNode: true,
            animationLimit: 1000,
            drillUpButton: {
              text: this.translateService.instant('back'),
            },
            dataLabels: {
              enabled: false,
            },
            levels: [
              {
                level: 1,
                dataLabels: {
                  enabled: true,
                  style: {
                    fontSize: '14px',
                    background: '#ccc',
                  },
                },
                borderWidth: 0.5,
                levelIsConstant: false,
              },
            ],
            data: points,
          },
        ],
        subtitle: {
          display: false,
          text: '',
        },
        title: {
          display: false,
          text: '',
        },
      };

      this.chart = this._HighCharts['Reflecx'].createOrUpdate(
        this.chart,
        this.chartContainer.nativeElement,
        chartOptions
      );
      if (this.annualHeapMapClick) {
        this.chart.series[0].drillToNode('id_0');
      }
    }
  }

  mapData(data: any) {
    var config = this.appConfig.getClientConfig('heat_map_widget_config');
    this.property = config['Fields'] == undefined ? ['number of surveys'] : config['Fields']['property'];
    const isMultiOrg = this.gbfService.isMultiOrg;
    let orgs: any = [];
    if (isMultiOrg) {
      orgs = this.gbfService.getMultiOrgHeatMap('multi_org', {}).Orgs;
    } else {
      orgs = [this.gbfService.getOrg('org', '')];
    }

    const maxLevel = Math.max.apply(
      Math,
      data.Item1.map(function (o: any) {
        return o.Level;
      })
    );
    const points: any[] = [];
    const questions = this.property.map((m) => m);
    const addAll = (data: any) => {
      let total = 0;
      questions.forEach((q) => {
        total += parseFloat(data[q]);
      });

      return total;
    };
    const buildRecords = (_level: number, parent: any = null, color = '#ccc') => {
      for (let level = _level; level <= maxLevel; level++) {
        var _records = data.Item1.filter((x: any) => x.Level === level && parent === x.ParentId);
        _records.forEach((record: any, index: number) => {
          const _record = points.find((x) => x._id === record.ParentId);
          let lastId = '';
          if (_record) {
            lastId = `${_record.id}_${index}`;
            if (!points.find((x) => x.id === lastId)) {
              points.push({
                _id: record.Id,
                id: lastId,
                name: record.Label,
                parent: _record.id,
                color: this.targetScoreColor(record, _record),
                value: record.info.QP_count || addAll(record.info),
              });
            }
          } else {
            lastId = `id_${index}`;
            if (!points.find((x) => x.id === `id_${index}`)) {
              points.push({
                _id: record.Id,
                id: `id_${index}`,
                name: record.Label,
                parent: null,
                color: this.targetScoreColor(record, null),
                value: record.info.QP_count || addAll(record.info),
              });
            }
          }
          buildRecords(level + 1, record.Id, '#007bff');
        });
      }
    };
    const falseCondition = [undefined, 'null', null];
    orgs.forEach((org: any, index: number) => {
      const parent = falseCondition.includes(org.OrgParent) ? 'null' : org.OrgParent;
      buildRecords(org.OrgLevel, parent, '#007bff');
    });
    this.calc_points = points.filter((x) => x.value > 0);
    return this.calc_points;
  }

  private targetScoreColor(record: any, parentRecord: any) {
    const config = this.appConfig.getClientConfig('heat_map_widget_config');
    const score = record.info.QP_count;
    const parentScore = parentRecord == null ? parentRecord : parentRecord.value;
    if (config['Fields'] && config['Fields']['mode'] && config['Fields']['mode']['key'] == 'OVERALL_COMP') {
      return this.overAllTargetColor(config, score);
    }
    if (config['Fields'] && config['Fields']['mode'] && config['Fields']['mode']['key'] == 'PARENT_COMP') {
      if (parentRecord == null && record.Level !== this.gbfService.authorizationData.LastNodeLevel) {
        this.annualHeapMapClick = true;
      }
      return this.paerentTargetColor(config, score, parentScore);
    } else {
      return '#007bff';
    }
  }
  private overAllTargetColor(config: any, score: number) {
    const targetScore = config['Fields']['mode']['target'];
    if (targetScore == score) {
      return config['Fields']['colors']['eq'];
    } else if (score > targetScore) {
      return config['Fields']['colors']['up'];
    } else if (score < targetScore) {
      return config['Fields']['colors']['down'];
    } else {
    }
  }
  private paerentTargetColor(config: any, score: number, parentRecord: number) {
    if (parentRecord == null) {
      return config['Fields']['colors']['up'];
    } else {
      if (parentRecord == score) {
        return config['Fields']['colors']['eq'];
      } else if (score > parentRecord) {
        return config['Fields']['colors']['up'];
      } else if (score < parentRecord) {
        return config['Fields']['colors']['down'];
      } else {
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });
  }
}
