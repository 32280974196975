import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { BreadCrumbService } from '@app/shared/services/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { DynamicTopicService } from '../dynamic-topic/dynamic-topic.service';
import { FiltersService } from '@app/shared/services/filters.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';
import { ExportService } from '@clearviewshared/services/export.service';
import { NavService } from '@reflecxshared/services/nav/nav.service';
import { SidebarService } from '@app/shared/services/sidebar.service';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { NgxPermissionsService, NgxPermissionsObject } from 'ngx-permissions';
import { Identifiers } from '@app/shared/services/app.config.type';

@Component({
  selector: 'app-dynamic-topic',
  templateUrl: './dynamic-topic.component.html',
  styleUrls: ['./dynamic-topic.component.scss'],
})
export class DynamicTopicComponent implements OnInit, OnDestroy {
  subscriptionExport: Subscription;
  subscriptionExportAll: Subscription;
  isApiHit = false;
  reportingRoutePrefix: any = environment.reportingRoutePrefix;
  prevevent = '';
  PayLoadEnum: any = {
    INPUT: 'Text',
    ADJECTIVE: 'ADJ',
    PREPOSITION: 'ADP',
    ADVERB: 'ADV',
    CONJUNCTION: 'CONJ',
    INTERJECTION: 'INTJ',
    NOUN: 'NOUN',
    VERB: 'VERB',
    PRONOUN: 'PRON',
  };
  drag: Array<any> = [
    { name: 'INPUT', value: '', isSelected: false },
    { name: 'ADJECTIVE', value: '', isSelected: false },
    { name: 'PREPOSITION', value: '', isSelected: false },
    { name: 'ADVERB', value: '', isSelected: false },
    { name: 'CONJUNCTION', value: '', isSelected: false },
    { name: 'INTERJECTION', value: '', isSelected: false },
    { name: 'NOUN', value: '', isSelected: false },
    { name: 'VERB', value: '', isSelected: false },
    { name: 'PRONOUN', value: '', isSelected: false },
  ];
  data: any = [];
  status: any;
  drop: Array<any> = [];
  Organization = '';
  PageSize = 200;
  PageNo = 1;
  api: Subscription;
  qureyStatus: any = '';
  totalSize: number = 0;
  isDropCall = false;
  isDropFixed = false;
  permissions: NgxPermissionsObject;
  includeUnassigend = false;
  repmod: string;
  isVoc: boolean = true;

  constructor(
    public translateService: TranslateService,
    private route: ActivatedRoute,
    private dynamicTopicService: DynamicTopicService,
    private navService: NavService,
    private sideBarService: SidebarService,
    private exportService: ExportService,
    public toastService: ToastService,
    private breadCrumbService: BreadCrumbService,
    private filterService: FiltersService,
    private gbfService: GlobalFilterService,
    public ngxPermission: NgxPermissionsService
  ) {}

  async GetMenuItems() {
    await this.exportService.sendMessageFromGlobalComponent('menu-report');
  }

  onFocus(e: any) {
    e.target.focus();
  }
  ngOnInit() {
    this.GetMenuItems();
    this.permissions = this.ngxPermission.getPermissions();
    this.includeUnassigend = this.permissions[Identifiers.ShowUnassignedCommentsVOC] ? true : false;
    this.repmod = this.route.snapshot.paramMap.get('mod');
    if (this.route.snapshot.queryParams['status']) {
      this.qureyStatus = this.route.snapshot.queryParams['status'];
    }
    if (this.navService.IsDynamicServices) {
      this.dynamicTopicService.dynamicSearchData = [];
      this.navService.IsDynamicServices = false;
    }
    if (this.dynamicTopicService.dynamicSearchData.length !== 0) {
      // console.log('____ is it running ...');
      this.dynamicTopicService.dynamicSearchData.map((res: any) => {
        this.drop.push({ name: res.key, value: res.value, isSelected: res.isSelected });
        if (res.key !== 'INPUT') {
          const dragIndex = this.drag.findIndex((data) => {
            return data.name === res.key;
          });
          this.drag[dragIndex].isSelected = true;
        }
      });
      this.searchAll('search');
      if (this.drop.length === 5) {
        this.isDropFixed = true;
      }
    }

    this.subscriptionExport = this.exportService.getMessage().subscribe((message: any) => {
      if (this.repmod === 'sm') {
        this.getRMDynamicTopicsExport();
      } else {
        this.getDynamicTopicsExport();
      }
    });

    // subscription for export all
    this.subscriptionExportAll = this.exportService.getMessageForExportAll().subscribe((message: any) => {
      if (this.repmod === 'sm') {
        this.getRMDynamicTopicsExportAll();
      } else {
        this.getDynamicTopicsExportAll();
      }
    });

    this.Organization = this.gbfService.getActiveOrgHierarchy().OrgName;
    const orgNames = JSON.parse(localStorage.getItem('hierarchy'));
    if (orgNames != null && orgNames.length > 0) {
      const count = orgNames.length;
      this.Organization = orgNames[count - 1].label;
    } else {
      this.Organization = this.Organization;
    }
    this.navService.setTitleClearView(this.translateService.instant('Dynamic Topic Search'));
    this.sideBarService.setActiveItems('Dynamic Topic Search');
    if (this.repmod === 'sm') {
      this.isVoc = false;
      this.breadCrumbService.resetModule();
      this.breadCrumbService.updateBreadCrumb(this.translateService.instant('Reputation Management'));
      this.breadCrumbService.url = '/insights/rm/dynamictopic';
    } else {
      this.breadCrumbService.resetModule();
      this.breadCrumbService.updateBreadCrumb(this.translateService.instant('VOC Dashboard'), '/insights/voc');
      this.breadCrumbService.updateBreadCrumb(this.translateService.instant('Dynamic Topic Search'));
    }

    if (this.route.snapshot.queryParams['status']) {
      this.status = this.route.snapshot.queryParams['status'];
      if (this.status === '1' || this.status === 'Positive') {
        this.filterService.setSentiment({ label: 'Positive', value: 0 });
      } else if (this.status === '2' || this.status === 'Neutral') {
        this.filterService.setSentiment({ label: 'Neutral', value: 1 });
      } else if (this.status === '3' || this.status === 'Negative') {
        this.filterService.setSentiment({ label: 'Negative', value: 2 });
      } else if (this.status === 'null' || this.status === 'All') {
        this.filterService.setSentiment({ label: 'All', value: null });
      }
    }

    if (this.route.snapshot.queryParams['status'] != undefined) {
      this.searchAll('SearchAll');
    }
    this.gbfService.gbfTabChange.subscribe(() => {
      this.removeAll();
    });
  }
  onDrop(event: any, type: string, text: any): void {
    let value = '';
    if (type === 'drop') {
      value = event.item.data;
    }
    if (type === 'click') {
      value = text;
    }
    if (!value) {
      moveItemInArray(this.drop, event.previousIndex, event.currentIndex);
    } else {
      const dropIndex = this.drop.findIndex((res) => {
        return res.name === value;
      });
      if (value === 'INPUT' && this.drop.length < 5) {
        // setTimeout(() => {
        //   this.inputEl.nativeElement.focus();
        // }, 100)
        this.drop.push({ name: 'INPUT', value: '', isSelected: false });
      } else {
        if (this.drop.length < 5 && dropIndex == -1) {
          const dragIndex = this.drag.findIndex((res) => {
            return res.name === value;
          });
          this.drag[dragIndex].isSelected = true;
          this.drop.push(this.drag[dragIndex]);
        }
      }
    }
    this.isDropCall = true;
    this.PageNo = 1;
    if (this.drop.length === 5) {
      this.isDropFixed = true;
    }
  }

  removeSelectedAction(value: string, index: number): void {
    this.drop.splice(index, 1);
    if (this.dynamicTopicService.dynamicSearchData.length !== 0) {
      this.dynamicTopicService.dynamicSearchData.splice(index, 1);
    }
    if (value === 'INPUT') {
      // console.log(this.drop);
    } else {
      const dragIndex = this.drag.findIndex((res) => {
        return res.name === value;
      });
      if (this.drag[dragIndex].name === 'INPUT') {
        this.drag[dragIndex].value = '';
      }
      this.drag[dragIndex].isSelected = false;
    }
    if (this.drop.length === 0) {
      this.data = [];
    }
    this.isDropCall = true;
    this.isDropFixed = false;
  }

  dynamicDescription(type: string) {
    if (type === 'INPUT') {
      return this.translateService.instant('Pulls results for comments containing specific key word(s)');
    }
    if (type === 'ADJECTIVE') {
      return this.translateService.instant('Pulls results for comments that contain any adjective');
    }
    if (type === 'PREPOSITION') {
      return this.translateService.instant('Pulls results for comments that contain any preposition');
    }
    if (type === 'ADVERB') {
      return this.translateService.instant('Pulls results for comments that contain any adverb');
    }
    if (type === 'CONJUNCTION') {
      return this.translateService.instant('Pulls results for comments that contain any conjunction');
    }
    if (type === 'INTERJECTION') {
      return this.translateService.instant('Pulls results for comments that contain any interjection');
    }
    if (type === 'NOUN') {
      return this.translateService.instant('Pulls results for comments that contain any noun');
    }
    if (type === 'VERB') {
      return this.translateService.instant('Pulls results for comments that contain any verb');
    }
    if (type === 'PRONOUN') {
      return this.translateService.instant('Pulls results for comments that contain any prenoun');
    }
    return '';
  }

  removeAll() {
    this.drop = [];
    this.drag[0].value = '';
    this.drag.map((res) => {
      res.isSelected = false;
    });
    this.data = [];
    this.dynamicTopicService.dynamicSearchData = [];
    this.isDropFixed = false;
  }
  applyFilters() {
    this.Organization = this.gbfService.getActiveOrgHierarchy().OrgName;

    if (this.drop.length !== 0) {
      this.searchAll('search');
    }
  }
  onTabSelect(event: any) {
    // if (this.drop.length !== 0 || this.prevevent !== event) {
    //   this.searchAll('search');
    //   this.prevevent = event;
    // }
    this.removeAll();
  }
  searchAll(type: string, loader: boolean = true) {
    let inputValue = '';
    let isValidInput: boolean = true;
    const payLoad: any = [];
    this.dynamicTopicService.dynamicSearchData = [];
    this.drop.map((data) => {
      if (this.PayLoadEnum[data.name] == 'Text') {
        if (
          (data.value.match(/"/g) !== null && data.value.match(/"/g).length < 2) ||
          new RegExp(
            '^""*[^"]*" ""*[^"]*"$|^""*[^"]*"or""*[^"]*"$|^""*[^"]*"OR""*[^"]*"$|^""*[^"]*" or ""*[^"]*"$|^""*[^"]*" or""*[^"]*"$|^""*[^"]*"or ""*[^"]*"$|^""*[^"]*" OR ""*[^"]*"$|^""*[^"]*"OR ""*[^"]*"$|^""*[^"]*" OR""*[^"]*"$|^""*[^"]*" -""*[^"]*"$|^-""*[^"]*" -""*[^"]*"$|^-""*[^"]*" ""*[^"]*"$|^[a-zA-Z 0-9 \']+$'
          ).test(data.value)
        ) {
          inputValue = data.value;
        } else {
          isValidInput = false;
          this.toastService.error(this.translateService.instant('dynamic_topic_search_input_validation'));
        }
      }
      this.dynamicTopicService.dynamicSearchData.push({
        key: data.name,
        value: data.value,
        isSelected: data.isSelected,
      });
      payLoad.push({
        key: data.name,
        value: this.PayLoadEnum[data.name] == 'Text' ? data.value : this.PayLoadEnum[data.name],
      });
    });
    if (type == 'search') {
      this.PageNo = 1;
      this.data = [];
    }
    if (this.api) {
      this.api.unsubscribe();
    }

    if (this.repmod === 'sm') {
      if (isValidInput) {
        this.api = this.dynamicTopicService
          .getRMDynamicTopics(payLoad, this.PageNo, this.PageSize, this.qureyStatus, loader, this.qureyStatus)
          .subscribe((res: any) => {
            if (res) {
              this.totalSize = res['Response']['Total'];
              this.data =
                this.PageNo === 1 ? res['Response']['Data']['data'] : this.data.concat(res['Response']['Data']['data']);
              this.isDropCall = false;
            }
          });
      }
    } else {
      if (isValidInput) {
        this.api = this.dynamicTopicService
          .getDynamicTopics(payLoad, this.PageNo, this.PageSize, this.qureyStatus, loader, this.qureyStatus)
          .subscribe((res: any) => {
            if (res) {
              this.totalSize = res['Response']['Total'];
              this.data =
                this.PageNo === 1 ? res['Response']['Data']['data'] : this.data.concat(res['Response']['Data']['data']);
              this.isDropCall = false;
            }
          });
      }
    }
  }

  OnTextChange(value: string, index: number) {
    // let pattern = /^[0-9a-zA-Z]+$/;
    this.drop[index].value = this.drop[index].value.replace(/[-[\]{}()*+?@!%&_=<>\\^$|#]/g, '');
    if (value.length > 50) {
      this.drop[index].value = value.slice(0, 50);
      this.PageNo = 1;
    }
  }
  getDynamicTopicsExport() {
    if (!(this.data.length > 0)) {
      this.toastService.info(this.translateService.instant('There is no record to export'));
      return;
    }

    let inputValue = '';
    const payLoad: any = [];
    this.drop.map((data) => {
      // inputValue = this.PayLoadEnum[data.name] == 'Text' ? data.value : '';
      if (this.PayLoadEnum[data.name] == 'Text') {
        inputValue = data.value;
      }
      payLoad.push({
        key: data.name,
        value: this.PayLoadEnum[data.name] == 'Text' ? data.value : this.PayLoadEnum[data.name],
      });
    });
    // if(inputValue){
    //   if(!this.validInput(inputValue)) return ''
    // }
    this.dynamicTopicService.downloadDynamicTopicsExport(payLoad, this.PageNo, this.PageSize, false, this.qureyStatus);
  }
  getRMDynamicTopicsExport() {
    if (!(this.data.length > 0)) {
      this.toastService.info(this.translateService.instant('There is no record to export'));
      return;
    }

    let inputValue = '';
    const payLoad: any = [];
    this.drop.map((data) => {
      // inputValue = this.PayLoadEnum[data.name] == 'Text' ? data.value : '';
      if (this.PayLoadEnum[data.name] == 'Text') {
        inputValue = data.value;
      }
      payLoad.push({
        key: data.name,
        value: this.PayLoadEnum[data.name] == 'Text' ? data.value : this.PayLoadEnum[data.name],
      });
    });
    // if(inputValue){
    //   if(!this.validInput(inputValue)) return ''
    // }
    this.dynamicTopicService.downloadRMDynamicTopicsExport(
      payLoad,
      this.PageNo,
      this.PageSize,
      false,
      this.qureyStatus
    );
  }

  getDynamicTopicsExportAll() {
    if (!(this.data.length > 0)) {
      this.toastService.info(this.translateService.instant('There is no record to export'));
      return;
    }
    let inputValue = '';
    const payLoad: any = [];
    this.drop.map((data) => {
      // inputValue = this.PayLoadEnum[data.name] == 'Text' ? data.value : '';
      if (this.PayLoadEnum[data.name] == 'Text') {
        inputValue = data.value;
      }
      payLoad.push({
        key: data.name,
        value: this.PayLoadEnum[data.name] == 'Text' ? data.value : this.PayLoadEnum[data.name],
      });
    });
    // if(inputValue){
    //   if(!this.validInput(inputValue)) return ''
    // }
    this.dynamicTopicService.downloadDynamicTopicsExport(payLoad, this.PageNo, this.PageSize, true, this.qureyStatus);
  }
  getRMDynamicTopicsExportAll() {
    if (!(this.data.length > 0)) {
      this.toastService.info(this.translateService.instant('There is no record to export'));
      return;
    }
    let inputValue = '';
    const payLoad: any = [];
    this.drop.map((data) => {
      // inputValue = this.PayLoadEnum[data.name] == 'Text' ? data.value : '';
      if (this.PayLoadEnum[data.name] == 'Text') {
        inputValue = data.value;
      }
      payLoad.push({
        key: data.name,
        value: this.PayLoadEnum[data.name] == 'Text' ? data.value : this.PayLoadEnum[data.name],
      });
    });
    // if(inputValue){
    //   if(!this.validInput(inputValue)) return ''
    // }
    this.dynamicTopicService.downloadRMDynamicTopicsExport(payLoad, this.PageNo, this.PageSize, true, this.qureyStatus);
  }

  onScroll() {
    if (this.totalSize) {
      if (this.totalSize !== this.data.length) {
        if (!this.isDropCall) {
          this.PageNo = this.PageNo + 1;
        }
        this.searchAll('', false);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.api) {
      this.api.unsubscribe();
    }
    this.subscriptionExport.unsubscribe();
    this.subscriptionExportAll.unsubscribe();
  }

  validInput(value: any) {
    let letters = /^[0-9a-zA-Z]+$/;
    if (!value.match(letters)) {
      // this.toastService.warning(this.translateService.instant('Please input alphanumeric characters only'));
      return false;
    }
    return true;
  }

  validateOutput(value: any) {
    // return value.replace(/\s/g, "");
    // return value.replace("[^a-zA-Z0-9]", "");
    value.trim;
    return value.replace(/“|,|"|\.|&|!|”/g, '').trim();
  }
}
